<template>
  <div class="olimp-admin-form admin-apps">
    <a-form-model
      ref="appForm"
      :model="appForm"
      :rules="appRules"
    >
      <a-tabs :default-active-key="defaultTabKey" @change="handleTabChange">
        <a-tab-pane :key="1" tab="Main" :forceRender="true">
          <a-form-model-item label="Platform" prop="platform">
            <a-radio-group v-model="appForm.platform">
              <a-radio value="ios">
                iOS
              </a-radio>
              <a-radio value="android">
                Android
              </a-radio>
              <a-radio value="web">
                Web
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-row>
            <a-col :span="12" class="pr-1">
              <a-form-model-item ref="name" label="Friendly name" prop="name">
                <a-input v-model="appForm.name" placeholder="Input friendly name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="pl-1">
              <a-form-model-item ref="bundle_name" label="Bundle ID" prop="bundle_name">
                <a-input v-model="appForm.bundle_name" placeholder="Input Bundle ID" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="appForm.platform !== 'web'">
            <a-col :span="24" class="pr-1">
              <a-form-model-item ref="schema" label="Schema" prop="schema" class="mr-1">
                <a-input v-model="appForm.schema" placeholder="Input application schema" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="appForm.platform !== 'web'">
            <a-col :span="12" class="pr-1">
              <a-form-model-item ref="fb_proj" label="Firebase project name" prop="fb_proj">
                <a-input v-model="appForm.fb_proj" placeholder="Input Firebase project name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="pl-1">
              <a-form-model-item ref="fb_name" label="Firebase collection name" prop="fb_name">
                <a-input v-model="appForm.fb_name" placeholder="Input Firebase collection name" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="appForm.platform !== 'web'">
            <a-col :span="24" class="">
              <a-form-model-item ref="sender_id" label="Firebase Key" prop="sender_id" class="ml-1">
                <a-select v-model="appForm.sender_id" placeholder="Select Sender ID">
                  <a-select-option v-for="sender in senderIds" :key="sender.id">
                    {{ sender.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="Banners" :forceRender="true" :disabled="this.appForm.platform === 'web'">
          <a-row>
            <a-col :span="10">
              <a-form-model-item label="Banners model">
                <a-radio-group v-model="appForm.properties.banners_model" button-style="solid" @change="onBannersModelChange">
                  <a-radio-button value="builtin">
                    Internal
                  </a-radio-button>
                  <a-radio-button value="kz">
                    External
                  </a-radio-button>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item v-if="appForm.properties.banners_model !== 'builtin'" ref="kz_banners_url" label="Banners import url" prop="kz_banners_url">
                <a-input v-model="appForm.properties.kz_banners_url" placeholder="Input json url" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane :key="3" tab="Firebase settings" :forceRender="true" :disabled="this.appForm.platform === 'web'">
          <a-row>
            <a-col :span="6" class="pr-1">
              <label>Setting name</label>
              <a-menu class="mt-1" @click="onSettingMenuClick">
                <a-menu-item v-for="setting in settings" :key="setting.id" >
                  {{ setting.name }}
                </a-menu-item>
<!--                <a-sub-menu title="submenu">-->
<!--                  <a-menu-item>submenu</a-menu-item>-->
<!--                </a-sub-menu>-->
              </a-menu>
            </a-col>
            <a-col :span="18" class="pl-1">
              <label>Setting content</label>
              <div>
                <div v-for="prop in Object.keys(activeSettingProps)" :key="prop">
                  {{ prop }}
                </div>
              </div>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
        <div class="mt-1">
          <hr>
          <div class="d-flex">
            <div class="mr-auto">
              <!-- <a-button v-if="!activeObj" key="back" @click="closeModal">
                Cancel
              </a-button> -->
              <CustomButton v-if="!activeObj" key="back" @click="closeModal">
                Cancel
              </CustomButton>

              <a-popconfirm v-else
                title="Sure to delete?"
                @confirm="deleteObj"
              >
                <!-- <a-button>Delete</a-button> -->
                <CustomButton>Delete</CustomButton>
              </a-popconfirm>
            </div>
            <div>
              <a-button key="submit" type="primary" :loading="loading" @click="onSubmit" class="auto">
                {{ activeObj ? 'Save' : 'Create' }}
              </a-button>
            </div>
          </div>
        </div>
    </a-form-model>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix'
import CustomButton from '@/components/custom/button/CustomButton'

export default {
  name: 'appModal',
  props: ['activeObj', 'senderIds', 'defaultTabKey'],
  mixins: [accessMix],
  components: {
    CustomButton,
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      // senderIds: undefined,
      settings: this.activeObj ? this.activeObj.settings : [],
      activeSettingKey: undefined,
      activeSettingProps: [],
      appForm: {
        name: this.activeObj ? this.activeObj.name : '',
        fb_name: this.activeObj ? this.activeObj.fb_name : '',
        fb_proj: this.activeObj ? this.activeObj.fb_proj : '',
        bundle_name: this.activeObj ? this.activeObj.bundle_name : '',
        platform: this.activeObj ? this.activeObj.platform : 'ios',
        schema: this.activeObj ? this.activeObj.schema : '',
        sender_id: this.activeObj ? this.activeObj.sender_id : 0,
        properties: {
          banners_model: this.activeObj && this.activeObj.admin_settings ? this.activeObj.admin_settings.banners_model : 'builtin',
          kz_banners_url: this.activeObj && this.activeObj.admin_settings ? this.activeObj.admin_settings.kz_banners_url : '',
        },
      },
      appRules: {
        name: [{ required: true, message: 'Please input name', trigger: 'blur' }],
        bundle_name: [{ required: true, message: 'Please input Bundle ID', trigger: 'blur' }],
        platform: [{ required: true, message: 'Please select platform', trigger: 'change' }],
        schema: [{ required: true, message: 'Please input application schema', trigger: 'blur' }],
        sender_id: [{ required: false, message: 'Please select Sender ID', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    closeModal() {
      console.log('closeEditModal')
      this.$emit('closeModalFunction')
    },
    onBannersModelChange(e) {
      console.log(e.target.value)
    },
    handleTabChange(key) {
      this.$emit('changeTab', key)
    },
    onSubmit() {
      this.$refs.appForm.validate(valid => {
        if (valid) {
          this.activeObj ? this.updateObj() : this.createObj()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onSettingMenuClick({ item, key, keyPath }) {
      this.activeSettingKey = key
      this.getSettingProps(key)
      console.log(this.activeSettingKey)
    },
    createObj() {
      this.loading = true
      const url = '/admin/applications'
      if (this.appForm.platform === 'web') {
        this.appForm.schema = 'https://'
      }
      return apiClient.post(url, this.appForm).then((response) => {
        const obj = response.data.data
        this.$notification.success({
          message: 'Application "' + response.data.data.name + '" successfully created',
          description: '',
        })
        this.loading = false
        this.closeModal()
        this.$emit('createApp', obj)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating new app',
          description: error.message,
        })
        this.loading = false
      })
    },
    updateObj() {
      this.loading = true
      const url = '/admin/applications/' + this.activeObj.application_id
      return apiClient.patch(url, this.appForm).then((response) => {
        const obj = response.data.data
        this.$notification.success({
          message: 'Application successfully updated',
          description: '',
        })
        this.loading = false
        this.closeModal()
        this.$emit('changeApp', obj)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating app',
          description: error.message,
        })
        this.loading = false
      })
    },
    deleteObj() {
      this.$emit('deleteApp', this.activeObj.application_id)
      this.closeModal()
    },
    async getSettingProps(key) {
      const url = `/admin/firebase/app-settings/${key}/props`
      const response = await apiClient.get(url)
      if (response.data.data) {
        this.activeSettingProps = response.data.data
        // this.apps.forEach(item => {
        //   if (item.is_default === 1) {
        //     this.checkedApp = item.application_id
        //   }
        // })
        console.log('setting props', this.activeSettingProps)
        // this.$emit('submitModalFunction', this.checkedApp)
      } else {
        console.log(response)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@/components/mixins.scss';
//.color-picker {
  //  .ant-input { width: 100px !important; }
  //}
  // .color-picker-container { background: transparent; border: 1px  }
  .admin-apps {
    .ant-upload {
      margin-top: -12px;
      button {
        padding: 0 16px !important;
      }
      .anticon {
        margin-right: 4px !important;
      }
    }
    .gif-tab-upload {
      height: 104px;
      max-height: 104px;
    }
    .gif-info-area {
      margin: 4px 2px 0 0;
      text-align: right;
      font-size: 0.75rem;
      color: $gray-8;
    }
    .extImportBtn {
      //display: inline-block !important;
      margin: 4px 0 0 0 !important;
    }
    .input-url-media {
      width: 274px;
    }
    .gif-uploader {
      //margin-top: 0 !important;
    }
    .processing-info {
      font-size: 0.8rem;
      padding: 0 8px 0 0;
      margin-top: 6px;
      color: $gray-8;
    }
    .gif-preview {
      width: 324px;
      height: 200px;
      border: 2px dashed #e4e9f0;
      text-align: center;
      .gif-preview-loading {
        position: absolute;
        text-align: center;
        width: 80px;
        height: 80px;
        background: transparent;
        margin: calc((200px - 80px) / 2) 0 0 calc((324px - 80px) / 2);
        .icn-loading {
          font-size: 80px;
          color: $primary;
        }
      }
      img.gif-preview-img {
        margin: 6px auto;
        max-width: 320px;
        max-height: 185px;
      }
    }
  }
</style>
<style scoped>

</style>
